.main {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  background-image: url('../../assets/dark-arkade.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.button {
  background: none;
  background-color: hsla(0%, 0%, 0%, .4);
  color: hsla(100%, 100%, 100%, 0.7);
  font-size: 25px;
  font-weight: 600;
  border: 3px solid black;
  border-radius: 3%;
  padding: 13px 30px;
  text-decoration: none;
  &:hover {
    background-color: hsla(0%, 0%, 0%, .5);
    color: 'black';
  }
}