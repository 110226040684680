.bio {
    position: relative;
    background-color: rgba(1, 1, 55, 0.4);
    padding: 25px 30px 40px 30px;
    z-index: 2;
    .container {
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        line-height: 1.6;
        letter-spacing: 1.4px;
        font-size: 18px;
        word-spacing: 2px;
        text-align: center;
    }
    img {
        width: 250px;
    }
}