.outerContainer {
    position: fixed;
    width: 100%;
    bottom: 100px;
    right: 20px;
    z-index: 5;
}

.container {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.playButton {
    position: absolute;
    padding: 15px;
    border-radius: 100%;
    background-color: rgba(1, 1, 55, 0.3);
    svg {
        color: white;
    }
}