.soundCloudWidget {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(1, 1, 55, 0.3);
}

.playlist {
    background-color: black;
    margin: 5px;
}

.iFrame {
    border: none;
    height: 300px;
    width: 100%;
}