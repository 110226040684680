.contact, .largeContact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #fff;
    width: 100%;
    padding: 80px 50px 40px 50px;
    gap: 10px;
}

.largeContact {
    align-items: center;
    padding: 80px 50px 40px 50px;
    gap: 10px;
}

.arkade, .largeArkade {
    display: flex;
    flex-direction: column;
    gap: 10px;
    img {
        width: 220px;
    }
}

.largeArkade {
    align-items: center;
    img {
        width: 200px;
    }
}

.button {
    z-index: 1000;
}