  .sideScrollContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(1, 1, 55, 0.2);
  }
  .parallax {
    overflow: hidden;
    letter-spacing: -2px;
    word-spacing: 5px;
    line-height: 0.8;
    margin: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
  
  .parallax .scroller {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 64px;
    display: flex;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    span {
      margin-right: 25px;
    }
  }
  
  .parallax span {
    margin-right: 30px;
  }
  