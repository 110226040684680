.appBar {
    background-color: transparent !important;
    box-shadow: none !important;
}
.navbar {
    background-color: rgba(1, 1, 55, 0.3) !important;
    color: white !important;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
}

.accordion {
    width: 100%;
    background-color: transparent !important;
    color: white !important;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: none !important;
}

.accordionSummary {
    border: none;
}

.accordionDetails {
    font-size: 25px;
    div {
        display: flex;        
        justify-content: center;
    }
    margin-bottom: 20px;
}

.title {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    div {
        display: flex;
    }
}

.link {
    color: white !important;
    cursor: pointer;
    text-decoration: none !important;
}

.logo {
    width: 200px;
    margin-left: 28px;
}